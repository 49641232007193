/* STylish button in cretate team */

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@import url('https://fonts.googleapis.com/css?family=Mukta:700');

$bg: #f3f8fa;
$white: #fff;
$black: #282936;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
  transition: $property $duration $ease;
}




.button_container {
  width: 100vw;
}

.learn-more {
  transform: rotate(180deg);
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  font-family: macer;

  &.learn-more {
    width: 12rem;
    height: auto;

    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $black;
      border-radius: 1.625rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;

        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;

          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }

    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      transform: rotate(180deg);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $black;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;
      font-family: macer;
      background: none;
    }
  }

  &:hover {
    .circle {
      width: 100%;

      .icon {
        &.arrow {
          background: $white;
          transform: translate(1rem, 0);
        }
      }
    }

    .button-text {
      color: $white;
      font-family: macer;
    }
  }
}



@supports (display: grid) {


  #container {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }
}


@media only screen and (max-width: 500px) {
  .learn-more{
    margin-top: -3%;
    scale: 0.75;
    font-size: 18px;
  }

}