* {
  margin: 0;
  padding: 0;
  background-color: rgba(4, 3, 10, 1);
  ::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(4, 3, 10, 1);
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #3c3c3c;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #3c3c3c;
  }
  
}
html, body {
  height: 95%;
  margin: 0;
  padding: 0;
  
}


@font-face {
  font-family: macer;
  src: url("./Fonts/f2.ttf");
}

@font-face {
  font-family: cabin;
  src: url("./Fonts/cabin.ttf");
}

.join {
  background-color: #1d1c2d;
  color: white;
  transition-duration: 0.5s;
  width: 25vw;
  height: 7vh;
  margin-bottom: 20px;
  border-radius: 10px;
  outline: none;
  border: 0px solid;
  align-self: center;
  font-family: macer;
  font-size: 2cqi;
}

.button-container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.join:hover {
  background-color: #00a1b1;
}
.details:hover {
  color: #00a1b1;
}

.reg {
  color: white;
  font-size: 6vw;
  font-weight: lighter;
  letter-spacing: 10px;
  text-align: center;
  font-family: macer, monospace;
  text-shadow: 0 5px 3px #000000;
}

button {
  background: none;
}
.timeline {
  display: flex;
  width: 25%;
  margin-left: 37.5%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20%;
}
.num {
  background-color: #1d1c2d;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  height: 22px;
  aspect-ratio: 1;
  border-radius: 22px;
}
.num-active {
  background-color: #00a1b1;
}
.numSpan {
  font-family: "cabin";
  background: none;
}

.three {
  height: 100vh;
  background-color: rgba(4, 3, 10, 1);

  overflow-x: hidden;
}

.regform {
  display: flex;
  /* margin-bottom: 12%; */
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.formrow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.formrow > input {
  padding: 20px 45px;

    background-color: #1D1C2D;
    border-radius: 10px;
    outline: 0px;
    /* border: 0px solid; */

  margin-bottom: 15px;
  margin-right: 10px;
  margin-left: 10px;

  text-align: center;
  font-family: macer;
  font-size: 20px;
  color: white;
}

.formrow.cntr {
  margin-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formrow.cntr > input {
  margin-right: 0px;
}

.formrow > input::placeholder {
  font-family: macer;
  font-size: 20px;
}

option {
  text-align: start;
}

.formrow > select {
  padding: 20px 120px;

  background-color: #1d1c2d;
  border-radius: 10px;
  outline: 0px;
  margin-left: 10px;
  /* border: 0px solid; */

  margin-bottom: 15px;
  margin-right: 10px;

  text-align: center;
  font-family: macer;
  font-size: 20px;
  color: white;
}

.formrow > select::placeholder {
  font-family: macer;
  font-size: 20px;
}

.submit {
  background-color: #00a1b1;
  margin-top: 30px;
  height: 50px;
  cursor: pointer;
}
.disabled {
  color: rgba(112, 112, 112, 0.404);
  transition-duration: 0.5s;
  width: 25vw;
  height: 7vh;
  margin-bottom: 20px;
  border-radius: 10px;
  outline: none;
  border: 0px solid;
  align-self: center;
  font-family: macer;
  font-size: 2cqi;
  background-color: #08596157;
  margin-top: 30px;
  height: 50px;
}

.submit:hover {
  background-color: #00a1b1;
  box-shadow: 0px 0px 15px #00a1b1;
}

.four {
  height: 150vh;
  background-color: rgba(4, 3, 10, 1);
  overflow-x: hidden;
}

.error {
  color: red;
  margin-bottom: 30px;
  text-align: center;
}
.msg_contain {
  width: 100vw;
  height: fit-content;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: macer;
}
.teammsg {
  font-size: 18px;
  margin-top: 45px;
  text-align: center;
  font-family: macer;
}
.message {
  min-height: 200px;
  border-radius: 65px;
  padding: 50px;
  max-width: 60%;
  background: #1d1c2d;
  backdrop-filter: saturate(180%) blur(10px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.message p {
  color: white;
  margin-top: 20px;
  background-color: #1d1c2d;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.message input {
  color: white;
  background-color: transparent;
  border: white 1px solid;
  padding: 8px;
  margin-right: 8px;
  width: 300px;
  margin-top: 20px;
  /* adjust the width as needed */
}

.message button {
  color: white;
  background-color: rgba(0, 140, 255, 0);
  border: white 1px solid;
  margin-top: 20px;
  padding: 8px;
  cursor: pointer;
}
.congrats {
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
}
.FormTitle {
  width: 100%;
  margin-top: 3vh;
  text-align: center;
}

.regform.joinin {
  margin-top: 25px;
}

.row {
  display: flex;
}

/* .register {
  margin-right: 35%;
}
 */
.teamname {
  font-size: 28px;
  width: 100%;
  text-align: center;
  margin: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1d1c2d inset;
  transition: background-color 5000s rgba(0, 0, 0, 0)-in-out 0s;
}

@media only screen and (max-width: 600px) {
  .timeline {
    width: 50%;
    margin-bottom: 10%;
    margin-left: 25%;
  }

  .navmenu {
    display: flex;
    /* margin-right: 5%; */
    justify-content: center;
    width: 100%;
  }

  .row {
    display: flex;
    padding: 0 5% 0 5%;
    margin-left: -10%;
    margin-right: 40px;
  }

  .register {
    /* margin-right: 15%;
    margin-left: -10%; */
    margin:0;
  }

  .demo {
    padding-right: 200%;
  }

  .message button {
    color: white;
    margin-top: 20px;
    padding: 8px;
    cursor: pointer;
  }
  
.regform.joinin {
    width: 100%;
  }
  

  .row {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 600px) {
.hister{
    margin-top: 20%;
    margin-left: 25%;
}
  body {
    margin: 0;
    padding: 0;
  }
  .message {
    max-width: 95%;
    height: 400px;
  }

  .message input {
    width: 80%;
  }

  .message button {
    width: 20%;
  }

  .teamname {
    font-size: 16px;
  }

  .formrow > input {
    padding: 15px 35px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .formrow > select {
    padding: 15px 110px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .reg {
    font-size: 5vw;
  }
  .countdown {
    font-size: 200%;
  }
}
/* .kuchbhi {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leaderboardcont {
  margin-top: 3%;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.leaderboardconthead {
  font: 10px;
  width: 100%;
  padding: 0 10px;
}
 */

 /* Center the table container */
.kuchbhi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.tableHeader{
  color: #00a1b1;
}
.leaderboardcont {
  width: 100%; /* Adjust width to make it responsive */
  max-width: 1200px; /* Set a max-width for larger screens */
  margin: 0.5rem auto; /* Center horizontally and add top/bottom margins */
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Reset the background color and box shadow for the table rows */
.team {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of text if necessary */
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  max-width: 1000px;
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 0.5rem;
  color: white;
}

/* Adjust the styles for table columns */
.teamname,
.pts,
.time {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
}


/* Responsive adjustments */
@media only screen and (max-width: 600px) {
  .teamname,
  .pts,
  .time {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .leaderboardcont {
    width: 95%; /* Ensure it fits within smaller viewports */
  }
}
h5,
h1 {
  margin: 0;
  padding: 0;
  background: none;
}

.team {
  position: relative;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 5px;
  padding: 12px 0;
  font-family: macer;
  border: 2px solid #00a1b1;
  border-radius: 6px;
}

.teamname {
  width: 45%;
  font-size: 25px;
  text-align: center;
}

.pts {
  width: 20%;
  font-size: 25px;
  text-align: center;
}
.time {
  width: 10%;
  text-align: center;
  font-size: 25px;
}

.Loader {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 10px solid #00a1b1;
  border-top: 10px solid #1d1c2d;
  animation: spin 1s linear infinite;
}

@keyframes spin{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.teamphone {
  font-size: 10px;
}
.regunder{
    color: white;
    font-weight: lighter;
    letter-spacing: 10px;
    text-align: center;
    font-family: macer, monospace;
    text-shadow: 0 5px 3px #000000;
    margin: 1% 0 1% 0;
    letter-spacing: 5px;
    font-size: 30px;
    
}

@media only screen and (max-width: 600px) {

    .reg{
        margin-top: 25%;
        margin-bottom: 0%;
        font-size: 30px;
    }
    .regunder{
        margin: 1% 0 10% 0;
        letter-spacing: 5px;
        font-size: 20px;
    }
    
    .join{
        height: 50px;
        width:40%;
        font-size: 15px;
    }
   
    .details{
        font-size: 15px;
        margin:2% 22% 10% 14%;
        text-overflow: unset;
        width: 50%;

    }

    .formrow>input{
        width:80%;
        font-size: 12px;
        height:50px;
        scale: 0.84  ;
    }
    .submit{
        width:50%;
        font-size: 16px;
        height:30px;
        margin-top: 3%;
    }
   
    
.teamname {
    width: 45%;
    font-size: 15px;
    text-align: center;
  }
  
  .pts {
      width: 20%;
      font-size: 15px;
    text-align: center;
  }
  .time {
      width: 10%;
      font-size: 15px;
    text-align: center;
  }

}

.buttccs{
  margin:0% 0 5% 0;
  padding-top: 3%;
}

  
  /* Responsive adjustments */
  @media only screen and (max-width: 600px) {
    .teamname,
    .pts,
    .time {
      font-size: 1rem; /* Adjust font size for smaller screens */
    }
  
    .leaderboardcont {
      width: 95%; /* Ensure it fits within smaller viewports */
    }
  }
  
 
  /* @media only screen and (max-width: 1000px) {
    .team {
      padding: 0.5rem; 
      flex-direction: column; 
      width: 80%; 
      
      margin: 1rem 1rem;
      margin-left: 1rem;
    }
  
    .teamname, .pts, .time {
      font-size: 1rem; 
      text-align: center; 
      margin: 0.3rem 0; 
    }
  
    
    .leaderboardcont {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      gap: 1rem; 
      width: 100%;
    }
  
    .team {
      margin: 0; 
      border: 1px solid #00a1b1; 
      border-radius: 5px; 
    }
  
    
    .formrow > input, .formrow > select {
      width: 100%; 
      font-size: 1rem; 
      box-sizing: border-box; 
      padding: 1rem; 
      margin: 0.5rem 0; 
    }
  }
   */
  
  /* Responsive styles for screens 600px and below 
@media only screen and (max-width: 600px) {
    .team {
      padding: 0.5rem; 
      flex-direction: column; 
      width: 100%; 
      margin-bottom: 1rem; 
    }
  
    .teamname, .pts, .time {
      font-size: 1rem; 
      text-align: center; 
      margin: 0.3rem 0; 
    }
  
    
    .leaderboardcont {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem; 
      width: 100%; 
    }
  
    .team {
      margin: 0; 
      border: 1px solid #00a1b1;
      border-radius: 5px; 
    }
  
    
    .formrow > input, .formrow > select {
      width: 100%;
      font-size: 1rem; 
      box-sizing: border-box; 
      padding: 1rem;
      margin: 0.5rem 0; 
    }
  }
  */
  /* Responsive adjustments 
@media only screen and (max-width: 1000px) {
  .leaderboardcont {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 1rem; 
    width: 100%;
  }

  .team {
    padding: 0.5rem; 
    flex-direction: column; 
    width: 80%;
    margin: 1rem auto; 
    border: 1px solid #00a1b1;
    border-radius: 5px; 
  }

  .teamname, .pts, .time {
    font-size: 1rem; 
    text-align: center; 
    margin: 0.3rem 0; 
  }
}
*/
/* Responsive styles for screens 600px and below 
@media only screen and (max-width: 600px) {
  .leaderboardcont {
    grid-template-columns: 1fr; 
    gap: 1rem;
    width: 95%; 
  }

  .team {
    width: 95%;
    margin-bottom: 1rem; 
  }
  
  .teamname, .pts, .time {
    font-size: 1rem; 
    text-align: center; 
    margin: 0.3rem 0; 
  }
}

*/





.memberRow{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.memberRow.teamname:nth-child(1) , .teamname:nth-child(4){
  width: 8%;
}



.checkDisp{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 4%;
  width: fit-content;
  padding: 12px 2%;
  border: 3px solid #00a1b1;
  border-radius: 6px;
  color: white;
  font-family: macer;
  font-size: 1.5rem;
  transition: ease-in-out 150ms;
  cursor: pointer;

}

.checkDisp>p{
  width: 100%;
  background: inherit;
}

.deleteIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border: 3px solid #00a1b1;
  border-radius: 6px;
  color: white;
  font-family: macer;
  margin-inline: 2%;
  font-size: 1.5rem;
  transition:   ease-in-out 150ms;
  cursor: pointer;
}

.deleteIcon:hover{
  background-color: #00a1b1;
}
.deleteIcon:hover .delicon{
  background-color: #00a1b1;
}


.deleteIcon:hover{
  background-color: #00a1b1;
}
.deleteIcon:hover .delicon{
  background-color: #00a1b1;
}


.tableHeader{
  font-size: 1.5rem;
  font-weight: 700 ;
}


.teamrow:nth-child(0){
  min-width: 60px;
}
.teamrow:nth-child(0){
  min-width: 60px;
}

.techStackHeading{
  width: fit-content !important;
}
@media (max-width: 1000px) {
  .tableHeader {
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .tableHeader {
    text-align: center;
    margin: 0 auto;
  }
}



/* Styles for screens 1000px and below 
@media only screen and (max-width: 1000px) {
  .leaderboardcont {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 1rem; 
    width: 90%; 
  }

  .team {
    flex-direction: column; 
    align-items: center;
    width: 90%; 
    margin: 1rem 0; 
  }

  .teamname, .pts, .time {
    width: 90%; 
    text-align: center; 
    margin: 0.3rem 0;
    font-size: 1rem; 
  }
}
*/
/* Styles for screens 600px and below 
@media only screen and (max-width: 600px) {
  .leaderboardcont {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem; 
    width: 90%;
  }

  .team {
    flex-direction: column;
    align-items: center; 
    width: 90%; 
    margin: 1rem 0;
  }

  .teamname, .pts, .time {
    width: 90%; 
    text-align: center;
    margin: 0.3rem 0; 
    font-size: 1rem; 
  }
  .checkDisp{
    font-size: 1rem;
    margin-bottom: 3rem;
  }
}

*/

@media only screen and (max-width: 600px) {
  .checkDisp{
    font-size: 1rem;
    margin-bottom: 3rem;
  }
}


/* Ensure table and cell styles align with original design */
.leaderboardTable {
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto;
  border-collapse: collapse;
  color: white;
}

.leaderboardTable th,
.leaderboardTable td {
  padding: 12px;
  
  text-align: center;
  font-family: macer;
  font-size: 1.5rem;
}

/* Header row specific styles */
.tableHeader {
  color: #00a1b1;
}

/* Specific column styles */
.teamname {
  width: 45%;
  text-align: center;
}

.pts {
  width: 20%;
}

.time {
  width: 10%;
}

/* Responsive styles 
@media only screen and (max-width: 600px) {
  .teamname, .pts, .time {
    font-size: 1rem;
  }

  .leaderboardTable {
    width: 95%;
  }
}

@media only screen and (max-width: 1000px) {
  .leaderboardTable th,
  .leaderboardTable td {
    padding: 0.5rem;
    font-size: 1rem;
  }
}
*/
/* For screens smaller than 600px, reduce font size for better visibility */
@media (max-width: 600px) {
  .leaderboardTable {
    font-size: 0.75rem; /* Adjust as needed */
  }
  
  .leaderboardTable th,
  .leaderboardTable td {
    padding: 0.5rem; /* Adjust padding for better spacing */
  }
  
  .leaderboardTable .teamname,
  .leaderboardTable .pts,
  .leaderboardTable .time {
    font-size: 0.7rem; /* Slightly smaller for tight content */
  }

  .leaderboardTable .techStackHeading {
    font-size: 0.65rem; /* Even smaller for potential long tech stack names */
  }
  
  .teamrow {
    height: auto; /* Ensure rows adjust their height based on content */
  }
}
.typography-message {
  margin-bottom: 2rem;
  text-align: center;
  color: white;
  font-family: 'macer', sans-serif !important;
  font-size: 1.25rem;
  line-height: 1.5;
}
@media only screen and (max-width: 600px) {
  .wrapper .typography-message {
    font-size: 0.6rem; 
    font-family: 'macer', sans-serif !important;
    margin: 0.5rem auto;
    align-items: center;
    margin-bottom: 2rem;
    line-height: 1.3;
    width: 70%;
  }
  .navigation{
    margin: 0.5rem auto;
  }
}
/* CSS for checkbox inside the table header */
.header-checkbox {
   
  cursor: pointer; /* Pointer cursor on hover */
  transform: scale(1.2); /* Increase size of the checkbox */
  accent-color: #00a1b1; /* Change color of the checkbox when checked */
}

/* Ensures the table header layout is not disturbed */
.tableHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive styling for small screens */
@media only screen and (max-width: 600px) {
  .header-checkbox {
    transform: scale(1); /* Adjust size of the checkbox for smaller screens */
  }
}

@media only screen and (min-width: 855px) {
  .femMem{
    width: 130px;
  }
}





.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #00a1b1; 
  
  text-align: center;
  padding: 10px 0;
  font-family: 'macer', sans-serif; 
  font-size: 0.875rem; 
}

.footer a {
  color: white; 
  text-decoration: none;
  background-color: transparent;
  font-weight: bold;
}

.footer a:hover {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .footer {
    font-size: 0.75rem; 
    padding: 8px 0; 
  }
}
